import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "category" }

import { ref, reactive } from 'vue'
import Breadcrumbs from '@/components/Breadcrumbs/index.vue'
import List from './list.vue'
import DemoImage from '@/assets/images/image-list/Rectangle 2500.png'


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {},
  emits: [],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const breadcrumbList = [
  { name: '定制产品', url: '', },
  { name: '全部产品', url: '', },
  { name: '公交车', url: '', },
]
const goods = reactive({
  list: [
    { thumbnail: DemoImage, name: 'E00 New Bus 2024 yellow big size', },
    { thumbnail: DemoImage, name: 'E00 New Bus 2024 yellow big size', },
    { thumbnail: DemoImage, name: 'E00 New Bus 2024 yellow big size', },
    { thumbnail: DemoImage, name: 'E00 New Bus 2024 yellow big size', },
    { thumbnail: DemoImage, name: 'E00 New Bus 2024 yellow big size', },
    { thumbnail: DemoImage, name: 'E00 New Bus 2024 yellow big size', },
    { thumbnail: DemoImage, name: 'E00 New Bus 2024 yellow big size', },
    { thumbnail: DemoImage, name: 'E00 New Bus 2024 yellow big size', },
    { thumbnail: DemoImage, name: 'E00 New Bus 2024 yellow big size', },
    { thumbnail: DemoImage, name: 'E00 New Bus 2024 yellow big size', },
    { thumbnail: DemoImage, name: 'E00 New Bus 2024 yellow big size', },
    { thumbnail: DemoImage, name: 'E00 New Bus 2024 yellow big size', },
    { thumbnail: DemoImage, name: 'E00 New Bus 2024 yellow big size', },
  ],
  title: 'E500 New Bus',
  total: 0,
  size: 10,
})

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Breadcrumbs, { config: breadcrumbList }),
    _createVNode(List, {
      goodsList: goods.list,
      title: goods.title
    }, null, 8, ["goodsList", "title"])
  ]))
}
}

})