import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "list" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "image" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "info" }
const _hoisted_8 = { class: "name" }

import { ref, reactive } from 'vue'
import { useRouter } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'list',
  props: {
  title: {
    type: String
  },
  goodsList: {
    type: Array,
    default() {
      return []
    }
  }
},
  emits: [],
  setup(__props, { expose: __expose, emit: __emit }) {

const router = useRouter()
const props = __props
const emits = __emit
const onItemClick = () => {
  router.push('/pixi')
}

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(__props.title), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.goodsList, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "item",
          key: index,
          onClick: ($event: any) => (onItemClick(item))
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: item.thumbnail,
              alt: ""
            }, null, 8, _hoisted_6)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("p", _hoisted_8, _toDisplayString(item.name), 1)
          ])
        ], 8, _hoisted_4))
      }), 128))
    ])
  ]))
}
}

})