import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "breadcrumbs" }

import { ref, reactive } from 'vue'
import { IBreadcrumbsItem } from './types'

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  config: {
    type: Array,
    default() {
      return []
    }
  }
},
  emits: [],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const list = ref<IBreadcrumbsItem[]>(props.config as IBreadcrumbsItem[] || [])

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, (item, index) => {
      return (_openBlock(), _createElementBlock("span", {
        key: item.name
      }, _toDisplayString(item.name) + " " + _toDisplayString(index !== list.value.length - 1 ? '/' : ''), 1))
    }), 128))
  ]))
}
}

})